import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import { Flex, Box } from "reflexbox";
import { BlogTitle, PostDate, PostBox } from "../styled/blog";
import { formatDate } from "../utils/date";
import SEO from "../components/seo";
import Tags from "../components/blog/tags";
import { Container } from "../components/layout/container";
import { Ornament } from "../components/layout/ornament";
import { LangLink } from "../components/lang-link";
import {Lang} from "../components/tr";
import BlogMenu from "../components/blog/blogMenu";

const IndexPage = ({ data, pageContext: { lang = Lang.cs }, location }) => {
  const postList = data.allMarkdownRemark;

  const categories = [].concat(...data.categories.edges.map((edge) => edge.node.frontmatter.tags));
  const uniqueCategories = [...new Set(categories)];

  return (
    <Layout lang={lang} location={location}>
      <SEO
        lang={lang}
        title="Blog"
        description={"Order your own nature landscape painting"}
      />
      <Container>
        <Ornament />
        <Flex flexWrap={"wrap"} justifyContent={"center"}>
          <Box width={[1, 1, 1, 2 / 3]} py={4}>
            {postList.edges.map(({ node }, i) => (
              <PostBox key={i}>
                <BlogTitle>
                  <LangLink to={`/blog${node.fields.slug}`}>
                    {node.frontmatter.title}
                  </LangLink>
                </BlogTitle>

                <PostDate style={{ textAlign: 'left' }}>{formatDate(node.frontmatter.date)}</PostDate>

                <p>{node.excerpt}</p>

                <Tags tags={node.frontmatter.tags} />
              </PostBox>
            ))}
          </Box>
          <Box width={[1,1,1, 1/3]} py={4}>
            <BlogMenu items={uniqueCategories} />
          </Box>
        </Flex>
      </Container>
    </Layout>
  );
};

export default IndexPage;

export const listQuery = graphql`
  query ListQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { glob: "**/blog/*.md" } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 410)
          frontmatter {
            date
            title
            tags
          }
        }
      }
    }

    categories: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { glob: "**/blog/*.md" } }
    ) {
      edges {
        node {
          frontmatter {
            tags
          }
        }
      }
    }
  }
`;
